<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import EventBus from '../../event-bus'

import Repository from "../../app/repository/repository-factory";
import {decimal, maxLength, required} from "vuelidate/lib/validators";
import Vue from "vue";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  components: {DatePicker},
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    },
    roadCard: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isBusy: false,
      submitted: false,
      form: {
        amount: this.roadCard.amount,
        dateOfReceipt: this.roadCard.dateOfReceipt,
        dateOfLastTrip: this.roadCard.dateOfLastTrip
      },
    };
  },
  validations: {
    form: {
      amount: {required, decimal, maxLength: maxLength(12)},
      dateOfReceipt: {required},
      dateOfLastTrip: {required},
    }
  },
  methods: {
    roadCardComplete() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }

      this.isBusy = true;

      let payload = {
        'amount': this.form.amount,
        'dateOfReceipt': this.form.dateOfReceipt,
        'dateOfLastTrip': this.form.dateOfLastTrip,
        'version': this.applicationExpectedVersion
      }

      ApplicationApplicationRepository.completeRoadCard(this.applicationId, payload).then(() => {
        EventBus.$emit('applicationChanged', '');
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Karta drogowa została uzupełniona!',
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
          }
        });
        this.isBusy = false;
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.isBusy = false;
        this.inProgress = false;
      })
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.form.$touch()
    }
  }
};
</script>
<template v-if="isBusy === false">
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="form-group">
          <label for="amount">Kwota:</label>
          <input
            id="amount"
            v-model="form.amount"
            step="0.01"
            min="0.00"
            max="9999999999.99"
            :disabled="disabled"
            type="number"
            class="form-control"
            placeholder="Kwota"
            value=""
            :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
          />
          <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
            <span v-if="!$v.form.amount.required">Ta wartość jest wymagana.</span>
            <span v-if="!$v.form.amount.decimal">Wpisz wartość liczbową (np. 200.00).</span>
            <span v-if="!$v.form.amount.greaterThanZero">Wartość musi być większa od 0.</span>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12">
        <div class="form-group">
          <label for="dateOfLastTrip">Data ostatniego przejazdu:</label>
          <date-picker
            id="dateOfLastTrip"
            v-model="form.dateOfLastTrip"
            :disabled="disabled"
            :first-day-of-week="1"
            placeholder="Data przejazdu"
            value-type="format"
            :class="{ 'is-invalid': submitted && $v.form.dateOfLastTrip.$error }"
            lang="pl">
          </date-picker>
          <div v-if="submitted && $v.form.dateOfLastTrip.$error" class="invalid-feedback">
            <span v-if="!$v.form.dateOfLastTrip.required">Ta wartość jest wymagana.<br></span>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12">
        <div class="form-group">
          <label for="dateOfReceipt">Data wpływu:</label>
          <date-picker
            id="dateOfReceipt"
            v-model="form.dateOfReceipt"
            :disabled="disabled"
            placeholder="Data wpływu"
            :first-day-of-week="1"
            value-type="format"
            :class="{ 'is-invalid': submitted && $v.form.dateOfReceipt.$error }"
            lang="pl">
          </date-picker>
          <div v-if="submitted && $v.form.dateOfReceipt.$error" class="invalid-feedback">
            <span v-if="!$v.form.dateOfReceipt.required">Ta wartość jest wymagana.<br></span>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12">
        <div class="centered">
          <button type="button" v-on:click="roadCardComplete" :disabled="disabled"
                  class="btn btn-sm btn-primary waves-effect waves-light">
            <i class="bx bx-bus mr-1"></i> Aktualizuj kartę drogową
            <template v-if="isBusy">
              <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
            </template>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
