<script>
import EventBus from '../../event-bus'

import Repository from "../../app/repository/repository-factory";
import {required} from "vuelidate/lib/validators";
import Vue from "vue";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  components: {},
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    },
    applicationStatuses: {
      type: Array,
      required: true
    },
    statuses: {
      type: Array,
      default: null,
      required: true
    },
    settlementStatus: {
      type: String,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      isBusy: false,
      submitted: false,
      form: {
        status: '',
      },
    };
  },
  validations: {
    form: {
      status: {required},
    }
  },
  methods: {
    changeStatus(keyStatus) {
      let item = this.statuses.find(element => element.value === keyStatus);
      return item.text;
    },
    changeApplicationStatus() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }

      this.isBusy = true;

      let payload = {
        'status': this.form.status,
        'version': this.applicationExpectedVersion,
      }

      ApplicationApplicationRepository.changeApplicationStatus(this.applicationId, payload).then(() => {
        EventBus.$emit('applicationChanged', '');
        EventBus.$emit('applicationSettlementChanged', '');
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Status wniosku został zmieniony!',
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
          }
        });
        this.isBusy = false;
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.isBusy = false;
        this.inProgress = false;
      })
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.form.$touch()
    },
    allowStatus() {
      let allow = [];
      if (this.lastStatus() === 'in_preparation') {
        allow.push(this.statuses.find(element => element.value === 'for_approval'));
        allow.push(this.statuses.find(element => element.value === 'incorrect'));
      }

      if (this.lastStatus() === 'for_approval') {
        allow.push(this.statuses.find(element => element.value === 'approved'));
        allow.push(this.statuses.find(element => element.value === 'correction'));
        allow.push(this.statuses.find(element => element.value === 'incorrect'));
      }

      if (this.lastStatus() === 'correction') {
        allow.push(this.statuses.find(element => element.value === 'for_approval'));
        allow.push(this.statuses.find(element => element.value === 'correction'));
        allow.push(this.statuses.find(element => element.value === 'incorrect'));
      }

      return allow;
    },
    lastStatus() {
      if (this.settlementStatus) {
        return this.settlementStatus;
      }

      return this.applicationStatuses.slice(-1)[0]['statusName'];
    }
  }
};
</script>
<template v-if="isBusy === false">

  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row" v-if="allowStatus().length > 0">
      <div class="col-md-8 col-lg-8">
        <div class="form-group">
          <b-form-select
            v-model="form.status"
            :options="allowStatus()"
            :class="{ 'is-invalid': submitted && $v.form.status.$error }">
          </b-form-select>
          <div v-if="submitted && $v.form.status.$error" class="invalid-feedback">
            <span v-if="!$v.form.status.required">Ta wartość jest wymagana.</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4">
        <div class="float-sm-left">
          <button type="button" v-on:click="changeApplicationStatus" :disabled="disabled"
                  class="btn btn-sm mt-1 btn-primary waves-effect waves-light">
             Zmień
            <template v-if="isBusy">
              <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
            </template>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12">
        <b-list-group flush>
          <b-list-group-item v-for="status in applicationStatuses" :key="status.statusName" v-bind:style="status.statusName === lastStatus() ? 'font-weight: bold;' : '' ">
            <i class="bx bx-chevrons-right mr-1"></i>
            <span data-toggle="tooltip" data-placement="top"
                  :title="'Ustawiono: ' + status.createdAt + ' | ' + status.createdBy">{{changeStatus(status.statusName) }}</span>
          </b-list-group-item>

          <b-list-group-item v-if="settlementStatus" v-bind:style="settlementStatus === lastStatus() ? 'font-weight: bold;' : '' ">
            <i class="bx bx-chevrons-right mr-1"></i>
            {{changeStatus(settlementStatus) }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

  </form>
</template>
