<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import EventBus from '../../event-bus'

import Repository from "../../app/repository/repository-factory";
import {decimal, maxLength, minLength, required} from "vuelidate/lib/validators";
import Vue from "vue";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");
//const greaterThanZero = (value) => value > 0;

export default {
  components: {DatePicker},
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      isBusy: false,
      submitted: false,
      form: {
        number: '',
        date: '',
        amount: ''
      },
    };
  },
  validations: {
    form: {
      number: {required, minLength: minLength(5), maxLength: maxLength(32) },
      date: {required},
      amount: {required, decimal, maxLength: maxLength(12)},
    }
  },
  methods: {
    addInvoiceToApplication() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }

      this.isBusy = true;

      let payload = {
        'number': this.form.number,
        'date': this.form.date,
        'amount': this.form.amount,
        'version': this.applicationExpectedVersion
      }

      ApplicationApplicationRepository.addInvoiceToApplication(this.applicationId, payload).then(() => {
        EventBus.$emit('applicationChanged', '');
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Faktura została dodana!',
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
            this.form.amount = '';
            this.form.number = '';
            this.form.date = '';
          }
        });
        this.submitted = false;
        this.isBusy = false;
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.isBusy = false;
        this.inProgress = false;
      })
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.form.$touch()
    }
  }
};
</script>
<template v-if="isBusy === false">
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-3 col-lg-3">
        <div class="form-group">
          <input
            id="number"
            v-model="form.number"
            type="text"
            class="form-control"
            placeholder="Numer faktury"
            value=""
            :class="{ 'is-invalid': submitted && $v.form.number.$error }"
          />
          <div v-if="submitted && $v.form.number.$error" class="invalid-feedback">
            <span v-if="!$v.form.number.required">Ta wartość jest wymagana.</span>
            <span v-if="!$v.form.number.minLength">Numer musi posiadać minimum 5 znaków.</span>
            <span v-if="!$v.form.number.maxLength">Numer nie może być dłuższy niż 32 znaki.</span>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="form-group">
          <input
            id="roadCardAmount"
            v-model="form.amount"
            type="text"
            class="form-control"
            placeholder="Kwota faktury"
            value=""
            :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
          />
          <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
            <span v-if="!$v.form.amount.required">Ta wartość jest wymagana.</span>
            <span v-if="!$v.form.amount.decimal">Wpisz wartość liczbową (np. 200.00).</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4">
        <div class="form-group">
          <date-picker
            id="date"
            v-model="form.date"
            placeholder="Data faktury"
            :first-day-of-week="1"
            value-type="format"
            :class="{ 'is-invalid': submitted && $v.form.date.$error }"
            lang="pl">
          </date-picker>
          <div v-if="submitted && $v.form.date.$error" class="invalid-feedback">
            <span v-if="!$v.form.date.required">Ta wartość jest wymagana.<br></span>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-lg-2">
        <div class="float-sm-left">
          <button type="button" v-on:click="addInvoiceToApplication"
                  class="btn btn-primary waves-effect waves-light">
             Dodaj fakturę
            <template v-if="isBusy">
              <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
            </template>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
