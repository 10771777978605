<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import EventBus from '../../event-bus'

import Repository from "../../app/repository/repository-factory";
import {decimal, maxLength, required, minValue} from "vuelidate/lib/validators";
import Vue from "vue";

const ApplicationSettlementRepository = Repository.get("ApplicationSettlementRepository");
const greaterThanZero = (value) => value > 0;

export default {
  components: {DatePicker},
  props: {
    settlementId: {
      type: String,
      required: true
    },
    settlementExpectedVersion: {
      type: Number,
      required: true
    },
    remainingToBePaid: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      isBusy: false,
      submitted: false,
      form: {
        beneficiaryAccountType: '',
        date: '',
        amount: ''
      },
      typeAccount: [
        {value: '', text: 'Wybierz typ konta'},
        {value: 1, text: 'Konto imienne'},
        {value: 2, text: 'Zbiórka publiczna'},
        {value: 3, text: 'Konto 1%'},
        {value: 4, text: 'Konto fundacji'}
      ]
    };
  },
  validations: {
    form: {
      beneficiaryAccountType: {required, minValue: minValue(1)},
      date: {required},
      amount: {required, decimal, greaterThanZero, maxLength: maxLength(12)},
    }
  },
  methods: {
    makeWithdrawal() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }

      this.isBusy = true;

      let payload = {
        'beneficiaryAccountType': this.form.beneficiaryAccountType,
        'date': this.form.date,
        'amount': this.form.amount,
        'version': this.settlementExpectedVersion
      }

      ApplicationSettlementRepository.makeWithdrawal(this.settlementId, payload).then(() => {
        EventBus.$emit('applicationSettlementChanged', '');
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Wypłata została zrealizowana!',
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
            this.form.amount = '';
            this.form.date = '';
            this.form.beneficiaryAccountType = '';
          }
        });
        this.submitted = false;
        this.isBusy = false;
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.isBusy = false;
        this.inProgress = false;
      })
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.form.$touch()
    },
    assignRemainingToBePaid() {
      if (this.remainingToBePaid > 0) {
        this.form.amount = this.remainingToBePaid;
      }
    }
  }
};
</script>
<template v-if="isBusy === false">
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-3 col-lg-3">
        <div class="form-group">
          <b-form-select
            v-model="form.beneficiaryAccountType"
            :options="typeAccount"
            :class="{ 'is-invalid': submitted && $v.form.beneficiaryAccountType.$error }">
          </b-form-select>
          <div v-if="submitted && $v.form.beneficiaryAccountType.$error" class="invalid-feedback">
            <span v-if="!$v.form.beneficiaryAccountType.required">Ta wartość jest wymagana.</span>
            <span v-if="!$v.form.beneficiaryAccountType.minValue">Musisz wybrać konkretny typ konta.</span>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="form-group">
          <input
            id="roadCardAmount"
            v-model="form.amount"
            type="text"
            class="form-control"
            placeholder="Kwota wypłaty"
            value=""
            v-on:dblclick="assignRemainingToBePaid()"
            :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
          />
          <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
            <span v-if="!$v.form.amount.required">Ta wartość jest wymagana.</span>
            <span v-if="!$v.form.amount.decimal">Wpisz wartość liczbową (np. 200.00).</span>
            <span v-if="!$v.form.amount.greaterThanZero">Wartość musi być większa od 0.</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4">
        <div class="form-group">
          <date-picker
            id="date"
            v-model="form.date"
            placeholder="Data wypłaty"
            :first-day-of-week="1"
            value-type="format"
            :class="{ 'is-invalid': submitted && $v.form.date.$error }"
            lang="pl">
          </date-picker>
          <div v-if="submitted && $v.form.date.$error" class="invalid-feedback">
            <span v-if="!$v.form.date.required">Ta wartość jest wymagana.<br></span>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-lg-2">
        <div class="float-sm-left">
          <button type="button" v-on:click="makeWithdrawal"
                  class="btn btn-primary waves-effect waves-light">
            <i class="bx bx-transfer mr-1"></i> Wypłać
            <template v-if="isBusy">
              <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
            </template>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
