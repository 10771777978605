<script>

import Repository from "../../app/repository/repository-factory";
import Vue from "vue";
import EventBus from "@/event-bus";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationComment: {
      required: true
    },
  },
  data() {
    return {
      isBusy: false,
      submitted: false,
      form: {
        comment: this.applicationComment,
      },
    };
  },
  methods: {
    changeComment() {
      this.formSubmit();

      let payload = {'comment': this.form.comment}

      ApplicationApplicationRepository.changeComment(this.applicationId, payload).then(() => {
        EventBus.$emit('applicationChanged', '');
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Komentarz został zaktualizowany!',
          showConfirmButton: false,
          timer: 500,
          onClose: () => {
          }
        });
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.inProgress = false;
      })
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
    }
  }
};
</script>
<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <b-form-textarea
          id="comment"
          v-model="form.comment"
          placeholder="Tutaj wpisz komentarz..."
          rows="6"
        ></b-form-textarea>
      </div>
      </div>
    <div class="row mt-2">
      <div class="col-md-12 col-lg-12">
        <div class="float-sm-right">
          <button type="button" v-on:click="changeComment"
                  class="btn btn-primary waves-effect waves-light">Zapisz komentarz
            <template v-if="isBusy">
              <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
            </template>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
